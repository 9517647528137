var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var TIME_QUERIES = ['last_3', 'last_5', 'last_10', 'current_season', 'custom'];
var DEFAULT_TIME_QUERY = 'last_3';
var CLUB_FRIENDLY_ID = '5f34ef232b928b3223dd632d';
var GAME_TYPE_FILTERS = [
    { value: 'all', label: 'All', gameTypes: [] },
    { value: 'pre-season', label: 'Pre Season', gameTypes: ['friendly'] },
    { value: 'regular-season', label: 'Regular', gameTypes: ['regular', 'relegation', null] },
    {
        value: 'post-season',
        label: 'Post Season',
        gameTypes: ['playoff', 'promotion', 'qualification'],
    },
    { value: 'cup', label: 'Cup', gameTypes: ['cup'] },
];
var GameStatsQuerySelector = (function () {
    function GameStatsQuerySelector(SeasonService, $scope, $mdMenu) {
        var _this = this;
        this.SeasonService = SeasonService;
        this.$scope = $scope;
        this.$mdMenu = $mdMenu;
        this.locationFilter = 'all';
        this.searchQuery = '';
        this.selectAllGames = false;
        this.initialValue = {};
        this.gameTypeFilter = 'all';
        this.hasPreSeasonGames = false;
        this.hasRegularSeasonGames = false;
        this.hasPostSeasonGames = false;
        this.hasCupGames = false;
        this.regularSeasonGamesCount = 0;
        this.onMenuClose = function () {
            _this.timeQuery = _this.initialValue.timeQuery;
            _this.gameSelections = _this.initialValue.gameSelections;
            _this.selectedLeagueId = _this.initialValue.selectedLeagueId;
            _this.selectedLeague = _this.initialValue.selectedLeague;
            _this.onlyPlayedGames = _this.initialValue.onlyPlayedGames;
            _this.gameTypeFilter = _this.initialValue.gameTypeFilter;
            _this.locationFilter = _this.initialValue.locationFilter;
            _this.searchQuery = _this.initialValue.searchQuery;
            _this.selectedTimeOption = _this.initialValue.selectedTimeOption;
            _this.filterGame();
        };
        this.gameSelections = {};
        this.selectedSeasonId = this.SeasonService.getSeasonId();
        this.timeQueries = TIME_QUERIES;
        this.timeQuery = this.timeQuery || DEFAULT_TIME_QUERY;
        this.selectedTimeOption = this.timeQuery;
        this.selectedLeagueId = 'all';
        this.filteredGames = [];
        this.onlyPlayedGames = false;
    }
    GameStatsQuerySelector.prototype.$onInit = function () {
        this.$scope.$on('$mdMenuClose', this.onMenuClose);
        this.onChange({
            $seasonId: this.selectedSeasonId,
            $timeQuery: this.timeQuery || DEFAULT_TIME_QUERY,
        });
    };
    GameStatsQuerySelector.prototype.$onChanges = function (changes) {
        if (changes.timeQuery) {
            this.timeQuery = changes.timeQuery.currentValue || DEFAULT_TIME_QUERY;
            this.selectedTimeOption = this.timeQuery;
        }
        if (changes.games) {
            this.filterGame();
        }
    };
    GameStatsQuerySelector.prototype.isFriendlyGame = function (game) {
        var _a;
        return game.gameType === 'friendly' || ((_a = game.league) === null || _a === void 0 ? void 0 : _a._id) === CLUB_FRIENDLY_ID;
    };
    GameStatsQuerySelector.prototype.showMenu = function ($mdMenu) {
        this.initialValue = {
            timeQuery: this.timeQuery,
            gameSelections: __assign({}, this.gameSelections),
            selectedLeagueId: this.selectedLeagueId,
            selectedLeague: this.selectedLeague,
            onlyPlayedGames: this.onlyPlayedGames,
            gameTypeFilter: this.gameTypeFilter,
            locationFilter: this.locationFilter,
            searchQuery: this.searchQuery,
            selectedTimeOption: this.selectedTimeOption,
        };
        $mdMenu.open();
    };
    GameStatsQuerySelector.prototype.onTimeOptionChange = function () {
        if (this.selectedTimeOption === 'custom') {
            this.timeQuery = 'custom';
            this.gameSelections = {};
            this.gameTypeFilter = 'all';
            this.locationFilter = 'all';
            this.searchQuery = '';
        }
        else {
            this.timeQuery = this.selectedTimeOption;
            this.gameSelections = {};
        }
        this.filterGame();
    };
    GameStatsQuerySelector.prototype.filterGame = function () {
        var _this = this;
        if (!this.games) {
            this.filteredGames = [];
            this.regularSeasonGamesCount = 0;
            return;
        }
        this.hasPreSeasonGames = false;
        this.hasRegularSeasonGames = false;
        this.hasPostSeasonGames = false;
        this.hasCupGames = false;
        this.regularSeasonGamesCount = 0;
        this.games.forEach(function (g) {
            var _a, _b;
            var leagueFilterPassed = _this.selectedLeagueId === 'all'
                ? true
                : _this.selectedLeagueId === CLUB_FRIENDLY_ID
                    ? ((_a = g.league) === null || _a === void 0 ? void 0 : _a._id) === CLUB_FRIENDLY_ID || g.gameType === 'friendly'
                    : ((_b = g.league) === null || _b === void 0 ? void 0 : _b._id) === _this.selectedLeagueId && g.gameType !== 'friendly';
            if (leagueFilterPassed) {
                var gameTypeFilter = GAME_TYPE_FILTERS.find(function (filter) { return filter.value === 'pre-season' && filter.gameTypes.includes(g.gameType); });
                if (gameTypeFilter) {
                    _this.hasPreSeasonGames = true;
                }
                var isRegularSeason = GAME_TYPE_FILTERS.find(function (filter) { return filter.value === 'regular-season' && filter.gameTypes.includes(g.gameType); });
                if (isRegularSeason || g.gameType === null) {
                    _this.hasRegularSeasonGames = true;
                }
                var isPostSeason = GAME_TYPE_FILTERS.find(function (filter) { return filter.value === 'post-season' && filter.gameTypes.includes(g.gameType); });
                if (isPostSeason) {
                    _this.hasPostSeasonGames = true;
                }
                var isCup = GAME_TYPE_FILTERS.find(function (filter) { return filter.value === 'cup' && filter.gameTypes.includes(g.gameType); });
                if (isCup) {
                    _this.hasCupGames = true;
                }
            }
        });
        if ((this.gameTypeFilter === 'pre-season' && !this.hasPreSeasonGames) ||
            (this.gameTypeFilter === 'regular-season' && !this.hasRegularSeasonGames) ||
            (this.gameTypeFilter === 'post-season' && !this.hasPostSeasonGames) ||
            (this.gameTypeFilter === 'cup' && !this.hasCupGames)) {
            this.gameTypeFilter = 'all';
        }
        this.filteredGames = this.games.filter(function (g) {
            var _a, _b;
            var leagueFilterPassed = _this.selectedLeagueId === 'all'
                ? true
                : _this.selectedLeagueId === CLUB_FRIENDLY_ID
                    ? ((_a = g.league) === null || _a === void 0 ? void 0 : _a._id) === CLUB_FRIENDLY_ID || g.gameType === 'friendly'
                    : ((_b = g.league) === null || _b === void 0 ? void 0 : _b._id) === _this.selectedLeagueId && g.gameType !== 'friendly';
            if (!leagueFilterPassed) {
                return false;
            }
            var gameTypeFilterPassed = true;
            if (_this.gameTypeFilter !== 'all') {
                var selectedFilter = GAME_TYPE_FILTERS.find(function (filter) { return filter.value === _this.gameTypeFilter; });
                if (selectedFilter) {
                    gameTypeFilterPassed =
                        selectedFilter.gameTypes.includes(g.gameType) ||
                            (_this.gameTypeFilter === 'regular-season' && g.gameType === null);
                }
            }
            if (!gameTypeFilterPassed) {
                return false;
            }
            if (_this.locationFilter !== 'all' && _this.teamId) {
                if (_this.locationFilter === 'home' && g.home.scId !== _this.teamId) {
                    return false;
                }
                if (_this.locationFilter === 'away' && g.away.scId !== _this.teamId) {
                    return false;
                }
            }
            if (_this.searchQuery) {
                var searchTerm = _this.searchQuery.toLowerCase();
                var homeTeam = (g.home.scName || g.home.name || '').toLowerCase();
                var awayTeam = (g.away.scName || g.away.name || '').toLowerCase();
                var gameDate = (g.date || '').toLowerCase();
                if (!homeTeam.includes(searchTerm) &&
                    !awayTeam.includes(searchTerm) &&
                    !gameDate.includes(searchTerm)) {
                    return false;
                }
            }
            return true;
        });
        this.regularSeasonGamesCount = this.games.filter(function (g) {
            var _a, _b;
            if (_this.selectedLeagueId === 'all') {
                return true;
            }
            else if (_this.selectedLeagueId === CLUB_FRIENDLY_ID) {
                return ((_a = g.league) === null || _a === void 0 ? void 0 : _a._id) === CLUB_FRIENDLY_ID || g.gameType === 'friendly';
            }
            else {
                return ((_b = g.league) === null || _b === void 0 ? void 0 : _b._id) === _this.selectedLeagueId && g.gameType !== 'friendly';
            }
        }).length;
        this.updateSelectAllState();
    };
    GameStatsQuerySelector.prototype.onGameTypeFilterChange = function () {
        this.filterGame();
    };
    GameStatsQuerySelector.prototype.onLocationFilterChange = function () {
        var prevLocationFilter = this.initialValue.locationFilter;
        if (this.isCustomGamesSelected && prevLocationFilter !== this.locationFilter) {
            this.gameSelections = {};
        }
        this.filterGame();
    };
    GameStatsQuerySelector.prototype.onSearchQueryChange = function () {
        this.filterGame();
    };
    GameStatsQuerySelector.prototype.onLeagueSelectionChange = function () {
        var _this = this;
        this.filterGame();
        this.selectedLeague = this.leagues.find(function (l) { return l._id === _this.selectedLeagueId; });
        this.gameSelections = {};
        if (!this.timeQuery && this.selectedTimeOption !== 'custom') {
            this.timeQuery = DEFAULT_TIME_QUERY;
            this.selectedTimeOption = DEFAULT_TIME_QUERY;
        }
    };
    GameStatsQuerySelector.prototype.toggleGameSelection = function (game) {
        this.gameSelections[game.gameId] = !this.gameSelections[game.gameId];
        this.updateSelectAllState();
    };
    GameStatsQuerySelector.prototype.onGameSelectionChange = function (_game) {
        this.updateSelectAllState();
    };
    GameStatsQuerySelector.prototype.toggleSelectAllGames = function () {
        var _this = this;
        var newState = this.selectAllGames;
        this.filteredGames.forEach(function (game) {
            _this.gameSelections[game.gameId] = newState;
        });
    };
    GameStatsQuerySelector.prototype.updateSelectAllState = function () {
        var _this = this;
        if (!this.filteredGames || this.filteredGames.length === 0) {
            this.selectAllGames = false;
            return;
        }
        var selectedCount = this.filteredGames.filter(function (game) { return _this.gameSelections[game.gameId]; }).length;
        this.selectAllGames = selectedCount === this.filteredGames.length;
    };
    GameStatsQuerySelector.prototype.apply = function () {
        var _this = this;
        this.onChange({
            $league: this.selectedLeagueId,
            $timeQuery: this.timeQuery === 'custom' ? null : this.timeQuery,
            $games: this.games.filter(function (g) { return !!_this.gameSelections[g.gameId]; }),
            $onlyPlayedGames: this.onlyPlayedGames,
            $gameTypeFilter: this.gameTypeFilter,
            $locationFilter: this.locationFilter,
        });
        this.$mdMenu.hide();
    };
    Object.defineProperty(GameStatsQuerySelector.prototype, "isCustomGamesSelected", {
        get: function () {
            return this.selectedTimeOption === 'custom';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(GameStatsQuerySelector.prototype, "totalSelectedGames", {
        get: function () {
            var _this = this;
            return Object.keys(this.gameSelections).filter(function (key) { return !!_this.gameSelections[key]; }).length;
        },
        enumerable: false,
        configurable: true
    });
    GameStatsQuerySelector.prototype.getAvailableGameTypeFilters = function () {
        var _this = this;
        return GAME_TYPE_FILTERS.filter(function (filter) {
            if (filter.value === 'all')
                return true;
            if (filter.value === 'pre-season')
                return _this.hasPreSeasonGames;
            if (filter.value === 'regular-season')
                return _this.hasRegularSeasonGames;
            if (filter.value === 'post-season')
                return _this.hasPostSeasonGames;
            if (filter.value === 'cup')
                return _this.hasCupGames;
            return false;
        });
    };
    return GameStatsQuerySelector;
}());
angular.module('app.general').component('gamestatsQuerySelector', {
    templateUrl: 'general/components/gamestats-query-selector.html',
    controller: GameStatsQuerySelector,
    bindings: {
        games: '<',
        leagues: '<',
        isDisabled: '<',
        timeQuery: '<',
        onlyPlayedGames: '<',
        onlyPlayedGamesEnabled: '<',
        onChange: '&',
        teamId: '<',
    },
});