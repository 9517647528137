(function () {
  'use strict';

  class SeasonSwitcherTwoController {
    private seasons: Array<{ id: string; startYear: number; endYear: number; name: string }> = [];
    private selectedSeason: {
      id: string;
      startYear: number;
      endYear: number;
      name: string;
    } | null = null;
    private loading: boolean = false;
    private error: string = '';
    private type: 'league' | 'team' | 'player';
    private id: string;

    constructor(
      private SeasonService,
      private SeasonFetcherService,
      private $state,
    ) {}

    $onInit() {
      this.fetchSeasons();
    }

    fetchSeasons() {
      this.loading = true;
      this.error = '';

      return this.SeasonFetcherService.getSeasons(this.type, this.id)
        .then((seasons) => {
          this.seasons = seasons;

          const seasonId = this.SeasonService.getSeasonId();

          if (seasonId) {
            this.selectedSeason = this.seasons.find((s) => s.id === seasonId);
          }
        })
        .catch((_error) => {
          this.error = 'Failed to load seasons';
        })
        .finally(() => {
          this.loading = false;
        });
    }

    onSeasonChange() {
      if (this.selectedSeason) {
        this.SeasonService.setSeasonId(this.selectedSeason.id);
        this.$state.go(this.$state.$current, null, { reload: true });
      }
    }
  }

  angular.module('app.general').component('seasonSwitcherTwo', {
    templateUrl: 'general/components/season-switcher-two.html',
    controller: SeasonSwitcherTwoController,
    bindings: {
      type: '@',
      id: '@',
    },
  });
})();
